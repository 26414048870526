<template>
  <div class="pre-loader">
    <div class="spin-box" @click.prevent="() => {}">
      <div class="loader" title="0">
        <loaderSvg></loaderSvg>
      </div>
    </div>
  </div>
</template>

<script>
import loaderSvg from './LoaderSvg.vue'
export default {
  components: {
    loaderSvg
  },
  name: 'AppLoader'
}
</script>
<style lang="scss" scopped>
.loader {
  height: 120px;
  width: 200px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}
.spin-box {
  height: 120px;
  width: 200px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  right: 0;
  text-align: center;
}
.pre-loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  left: 0;
  background: #fff;
  opacity: 0.85;
}
</style>
