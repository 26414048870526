export default {
  data () {
    return {
      documentScrollHeight: null,
      scrollHeightCheckInterval: null
    }
  },
  methods: {
    sendResizeContent (isInitialized, forceScrollToTop) {
      const iframeId = this.$store.getters.parentIframeId
      const allowAutoGrow = this.$store.getters.allowAutoGrow
      if (isInitialized === undefined) {
        isInitialized = false
      }
      if (iframeId === null || !allowAutoGrow) {
        return false
      }
      if (forceScrollToTop) {
        if (!this.$store.getters.emitScrollToTop) {
          forceScrollToTop = false
        }
      } else {
        forceScrollToTop = false
      }
      if (iframeId) isInitialized = true
      let _height = `${document.body.scrollHeight}px`
      const elem = document.getElementById('dialog')
      if (elem) {
        if (elem.scrollHeight > document.body.scrollHeight) {
          // 150 extra space between dialog
          _height = String(elem.scrollHeight + 150) + 'px'
        }
      }
      const payload = {
        event: 'resizeContent',
        params: {
          height: _height,
          width: document.body.scrollWidth + 'px',
          iFrameId: iframeId,
          scrollToTop: forceScrollToTop,
          isInitialized: isInitialized
        }
      }
      window.parent.postMessage(payload, '*')
    },
    requestInitialPostMessage () {
      const payload = {
        event: 'initPoll'
      }
      window.parent.postMessage(payload, '*')
    }
  }
}
