import axios from 'axios'
import store from '../store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000,
  withCredentials: true
})

instance.interceptors.request.use((config) => {
  store.dispatch('setShowLoader', true)
  return config
}, (error) => {
  store.dispatch('setShowLoader', false)
  return Promise.reject(error)
})
instance.interceptors.response.use(config => {
  store.dispatch('setShowLoader', false)
  return config
}, (error) => {
  store.dispatch('setShowLoader', false)
  return Promise.reject(error)
})
export default instance
