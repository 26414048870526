import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: '',
    csrf: '',
    domain: null,
    year: null,
    dealerCode: null,
    showLoader: false,
    translations: {},
    emitScrollToTop: true,
    iframeId: null,
    allowAutoGrow: true
  },
  getters: {
    accessToken: state => state.accessToken,
    approvalYear: state => state.year,
    dealerCode: state => state.dealerCode,
    showLoader: state => state.showLoader,
    translations: state => state.translations,
    emitScrollToTop: state => state.emitScrollToTop,
    allowAutoGrow: state => state.allowAutoGrow,
    parentIframeId: state => state.iframeId,
    domain: state => state.domain
  },
  mutations: {
    set_access_token (state, data) {
      state.accessToken = data
    },
    set_approval_year (state, data) {
      state.year = data
    },
    set_dealer_code (state, data) {
      state.dealerCode = data
    },
    set_loader_toggle (state, data) {
      state.showLoader = data
    },
    set_translations (state, data) {
      state.translations = data
    },
    set_scroll_to_top (state, data) {
      state.emitScrollToTop = data
    },
    set_allow_auto_grow (state, data) {
      state.allowAutoGrow = data
    },
    set_iframe_id (state, data) {
      state.iframeId = data
    },
    set_domain (state, data) {
      state.domain = data
    }
  },
  actions: {
    setAccessToken ({ commit }, data) {
      commit('set_access_token', data)
    },
    setApprovalYear ({ commit }, data) {
      commit('set_approval_year', data)
    },
    setDealerCode ({ commit }, data) {
      const year = data || new Date().getFullYear()
      commit('set_dealer_code', year)
    },
    setShowLoader ({ commit }, data) {
      commit('set_loader_toggle', data)
    },
    setTranslations ({ commit }, data) {
      commit('set_translations', data)
    },
    setScrollToTop ({ commit }, data) {
      commit('set_scroll_to_top', data)
    },
    setAllowAutoGrow ({ commit }, data) {
      commit('set_allow_auto_grow', data)
    },
    setIframeId ({ commit }, data) {
      commit('set_iframe_id', data)
    },
    setDomainUrl ({ commit }, data) {
      commit('set_domain', data)
    }
  },
  modules: {
  }
})
